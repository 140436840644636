.cover {
  background-color: black;
  position: relative;
  width: 100vw;
  height: 90vh;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  background-position-y: 0px;
}

.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 1%,
    transparent 40%,
    transparent 80%,
    rgba(0, 0, 0, 0.35) 90%
  );
}

.player {
  z-index: 10;
  border: 4px solid #ccc !important;
}

.player > div > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
