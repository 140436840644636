.page {
  margin-top: 20px;
  /* display: flex; */
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
}
.results {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  transition-property: transform;
  box-sizing: content-box;
  overflow: visible;
}

.item {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  width: 300px;
  height: 140px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  -webkit-transition: 250ms all;
  transition: 250ms all;
  position: relative;
  flex-shrink: 0;
  margin: 15px 15px;
}

.item > div {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    black 0%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.019) 88%,
    rgba(0, 0, 0, 0.037) 82.85%,
    rgba(0, 0, 0, 0.075) 75.5%,
    rgba(0, 0, 0, 0.15) 45%,
    rgba(0, 0, 0, 0) 50%
  );
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  width: 100%;
}

.search > div {
  width: 100%;
}

.search > i {
  font-size: 18px;
  opacity: 0.7;
}

@media only screen and (max-width: 630px) {
  .search > i {
    font-size: 12px;
  }
}

@media only screen and (max-width: 320px) {
  .search > div {
    width: 40px;
  }
}
