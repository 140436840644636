.add {
  height: 50px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.add:hover {
  transform: scale(1.3);
}

.disabled:hover {
  opacity: 0.3;
}

.add > i {
  font-size: 40px !important;
}

@media only screen and (max-width: 600px) {
  .add {
    height: 20px;
  }

  .add > i {
    font-size: 20px !important;
  }
}
