.main {
  color: white;
  padding-bottom: 96px;
  overflow-x: hidden;
  margin-top: 8vh;
}

.list {
  margin-left: 40px;
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .list {
    margin-left: 5px;
  }
}
