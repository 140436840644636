.mheader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 100;
  background: rgba(18, 18, 18, 0.95);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  max-height: 56px;
}

.logo {
  font-size: 2em;
  font-weight: 200;
  color: #dd3532;
  text-shadow: 1px 1px 0 #600, -1px -1px 0 #600, 1px -1px 0 #600, -1px 1px 0 #600, 1px 1px 0 #600;
  height: 100%;
  align-items: center;
  display: flex;
  opacity: 1 !important;
}

.logo > a {
  color: #dd3532;
}

.logo > a:visited {
  color: #dd3532;
}

.logo > a > img {
  display: none;
}

@media only screen and (max-width: 928px) {
  .logo > a > img {
    width: 28px;
    height: 28px;
    display: block;
    border-radius: 3px;
  }

  .logo > a > span {
    display: none;
  }
}

.navigation {
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: 40px;
}

.rightNav {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
  margin-left: -5px;
}

.leftNav {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
}

.leftNav > div {
  padding-right: 24px;
  opacity: 0.7;
}

.active {
  opacity: 1 !important;
}

.random > a > span {
  padding-left: 5px;
}

@media only screen and (max-width: 630px) {
  .rightNav {
    flex: 0 0 45%;
    display: flex;
    justify-content: flex-end;
  }

  .leftNav {
    flex: 0 0 55%;
    font-size: 10px;
    padding-right: 0px;
  }

  .leftNav > div {
    padding-right: 12px;
    opacity: 0.7;
  }

  .latest {
    display: none;
  }

  .random > a > span {
    display: none;
  }

  .navigation {
    margin-left: 12px;
  }
}
