.content {
  width: 100%;
  margin: 3vw 0;
  min-height: 200px;
}

.loading {
  height: 100%;
  display: flex;
  vertical-align: middle;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .content {
    margin: 2vw 0;
    min-height: 150px;
  }
}

@media only screen and (device-width: 768px) {
  .content {
    margin-bottom: 10vw;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .content {
    margin-bottom: 6vw;
  }
}
