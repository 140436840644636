.content {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 1;
  flex-wrap: wrap;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  border: 2px solid #dd3532;
}

.background {
  opacity: 0.4;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 100%;
  height: 100%;
}

.animation {
}

.animation:hover .detail {
  opacity: 1;
  transform: scale(1);
  -ms-transform: scale(1); /* IE 9 */
  -webkit-transform: scale(1);
  transition-duration: 700ms;
}

.animation:active .detail {
  opacity: 1;
  transform: scale(1);
  -ms-transform: scale(1); /* IE 9 */
  -webkit-transform: scale(1);
  transition-duration: 700ms;
}

.animation:target .detail {
  opacity: 1;
  transform: scale(1);
  -ms-transform: scale(1); /* IE 9 */
  -webkit-transform: scale(1);
  transition-duration: 700ms;
}

.hitbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 65%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.detail {
  position: absolute;
  background-color: black;
  z-index: 40;
  opacity: 0;
  top: -40%;
  left: -20%;
  width: 140%;
  height: 180%;
  background: #1a1a1a;
  /* transform: scale(1);;
    transition-duration: 1s; */
}

@media only screen and (max-width: 600px) {
  .hitbox {
    font-size: 12px;
  }

  .detail {
    top: -2px;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    z-index: 40;
    opacity: 1;
  }
}
@media only screen and (device-width: 768px) {
  .detail {
    opacity: 1;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .detail {
    opacity: 1;
  }
}

.overview {
  flex: 0 1 80%;
  text-align: left;
  z-index: 1;
}

.overviewContent {
  margin-left: 10px;
  margin-bottom: 10px;
}

.actions {
  flex: 0 0 20%;
  z-index: 1;
}

.actionsContent {
  padding-left: 10px;
  padding-bottom: 10px;
}

.actionsContent > div > i {
  font-size: 22px;
}

@media only screen and (max-width: 600px) {
  .overview {
    flex: 0 1 80%;
    text-align: left;
  }

  .overviewContent {
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .actions {
    flex: 0 0 20%;
    text-align: left;
  }

  .actionsContent {
    padding-right: 10px;
    padding-left: 0px;
    padding-bottom: 10px;
  }

  .actionsContent > div > i {
    font-size: 16px;
  }
}

.title {
  flex: 0 1 40%;
  display: inline-block;
  justify-content: left;
  align-items: center;
  font-weight: bold;
  padding-top: 3px;
}

.play {
  color: #dd3532;
  flex: 0 0 60%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 3px;
}

.play > a > i {
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
  font-size: 70px;
  cursor: pointer;
  color: #dd3532;
}

.play > a > i:hover {
  transform: scale(1.4);
  opacity: 1;
}

.channel {
  font-size: 20px;
  justify-content: left;
  display: flex;
  font-weight: bold;
}

.channel > i {
  margin-right: 10px;
}

.description {
  margin-top: 10px;
  font-size: 16px;
  justify-content: center;
  display: inline-flex;
  font-size: 16px;
  align-items: center;
  text-align: center;
  align-content: center;
}

.description > div {
  width: 100%;
}

.description > div > i {
  font-size: 20px;
  width: 100%;
  margin-top: 2px;
}

.info {
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .channel {
    display: none;
    font-size: 10px;
  }

  .play > a > i {
    opacity: 1;
    font-size: 30px;
  }

  .description {
    width: 100px;
    font-size: 10px;
  }

  .description > div > i {
    font-size: 12px;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .info {
    display: none;
  }
}
