.swiper-custom-container {
  overflow: visible;
}

.swiper-custom-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  /* display: flex; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  /* align-items: center; */
  width: 300px;
  height: 140px;
  /* border: solid 1px rgba(0, 0, 0, 1); */
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0, transparent 48px, transparent 40%, black 99%);
  -webkit-transition: 250ms all;
  transition: 250ms all;
  position: relative;
  flex-shrink: 0;
}

.swiper-content {
  width: 100%;
  background: linear-gradient(
    to bottom,
    black 0%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.019) 88%,
    rgba(0, 0, 0, 0.037) 82.85%,
    rgba(0, 0, 0, 0.075) 75.5%,
    rgba(0, 0, 0, 0.15) 45%,
    rgba(0, 0, 0, 0) 50%
  );
  height: 100%;
}

.swiper-previous-margin {
  transform: translate3d(60px, 0, 0);
}

.swiper-next-margin {
  transform: translate3d(-60px, 0, 0);
}

.swiper-custom-slide:first-child:hover {
  z-index: 20;
  transition-delay: 400ms;
  transform: translate3d(60px, 0, 0);
}

.swiper-custom-slide:last-child {
  margin-right: 85px;
}

.swiper-custom-slide:last-child:hover {
  z-index: 20;
}

.swiper-custom-next {
  z-index: 20;
  top: 16%;
  height: 100%;
  background: rgba(20, 20, 20, 0.5);
  color: white;
  font-weight: bold;
  width: 4%;
  right: 0px;
}

.swiper-custom-prev {
  z-index: 20;
  top: 16%;
  height: 100%;
  background: rgba(20, 20, 20, 0.5);
  color: white;
  font-weight: bold;
  width: 4%;
  left: -40px;
}

@media only screen and (max-width: 600px) {
  .swiper-custom-slide {
    width: 45%;
    height: 90px;
    font-size: 14px;
  }

  .swiper-custom-next {
    top: 25%;
    width: 3%;
  }

  .swiper-custom-next:after {
    font-size: 20px;
  }

  .swiper-custom-prev {
    top: 25%;
    width: 3%;
    left: -5px;
  }

  .swiper-custom-prev:after {
    font-size: 20px;
  }

  .swiper-previous-margin {
    transform: translate3d(0px, 0, 0);
  }

  .swiper-next-margin {
    transform: translate3d(0px, 0, 0);
  }

  .swiper-custom-slide:first-child:hover {
    transform: translate3d(0px, 0, 0);
  }

  .swiper-custom-slide:last-child {
    margin-right: 60px;
  }

  .swiper-custom-slide:last-child:hover {
    z-index: 40;
  }
}

.swiper-button-disabled {
  visibility: hidden;
}
