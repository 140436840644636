.back {
  padding: 8px;
  max-height: 56px;
  min-width: 60px;
  margin-left: 8px;
  margin-right: 8px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: rgb(255, 255, 255, 0.6);
  -webkit-transition: all 0.3s; /* Safari */
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back span {
  margin-left: 10px;
}
.back:hover {
  background: rgba(255, 255, 255, 0.25);
  opacity: 1;
}
.back:focus {
  outline: 0;
}
.back i {
  color: #7c7c7c;
  font-size: 32px !important;
}
.back:hover i {
  color: white;
}

.navigation {
  display: flex;
  width: 100%;
  height: 100%;
}

.rightNav {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
  vertical-align: middle;
}

.leftNav {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
}

.leftNav > div {
  padding-right: 24px;
  opacity: 0.7;
}

.active {
  opacity: 1 !important;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.flash {
  color: white;
  font-weight: bold;
  animation: blinker 1s linear 2;
}

.random > a > span {
  padding-left: 5px;
}

@media only screen and (max-width: 600px) {
  .rightNav {
    flex: 0 0 45%;
    display: flex;
    justify-content: flex-end;
  }

  .leftNav {
    flex: 0 0 55%;
    font-size: 10px;
    padding-right: 0px;
  }

  .leftNav > div {
    padding-right: 12px;
    opacity: 0.7;
  }

  .latest {
    display: none;
  }

  .random > a > span {
    display: none;
  }

  .navigation {
    margin-left: 0px;
  }
}
