.footerbtn {
  background: transparent;
  border: none;
  border-radius: 8px;
  color: rgb(255, 255, 255, 0.6);
  -webkit-transition: all 0.3s; /* Safari */
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
}

.footerbtn i {
  min-width: 30%;
  text-align: center;
}
.footerbtn span {
  min-width: 70%;
}

.menuButton {
  color: #7c7c7c;
  opacity: 0.6;
  font-size: 32px !important;
}

.menuButton:hover {
  opacity: 1;
}
.menuButton:focus {
  outline: 0;
}

@media only screen and (max-width: 700px) {
  .menuButton {
    margin-right: 20px;
  }
}
