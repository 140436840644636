/* CSS files add styling rules to your content */

body {
  font-family: 'Trebuchet MS', Helvetica, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background: #1a1a1a;
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  --overscroll-behavior-y: contain;
  color: white;
}

button:hover,
a:hover {
  cursor: pointer;
  opacity: 0.8;
}

a {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

a,
a:visited,
a:hover {
  color: white;
  text-decoration: none;
}

.noscroll {
  overflow: hidden;
}

.centered-content {
  text-align: center;
}

.spinner {
  margin-top: 10vh;
  width: 100%;
  color: white;
  text-align: center;
}
