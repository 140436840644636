.center {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.default {
  width: 100%;
  height: 100%;
  margin: 10px 10px;
  color: #808080;
}

.default > h2 {
  color: white;
}
